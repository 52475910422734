function getFormattedQuantity(value: number, maxDecimals = 2): string {
  const fixedStr = value.toFixed(maxDecimals)
  const fixedNum = Number(fixedStr)

  if (Number.isNaN(fixedNum)) {
    return ''
  }

  return fixedNum.toString()
}

export default getFormattedQuantity
