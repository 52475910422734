import { CheckItem, SaleGroupItem } from '@ancon/wildcat-types'

function createSalesGroupItemFromCheckItem(
  checkItem: CheckItem,
): SaleGroupItem {
  return {
    ids: [checkItem.id],
    discount: checkItem.discount,
    discountInfo: checkItem.discountInfo,
    modifications: checkItem.modifications,
    parentItemId: checkItem.parentItemId,
    product: checkItem.product,
    quantity: checkItem.quantity,
    totalDiscountAmountInclTax: checkItem.totalDiscountAmountInclTax,
    totalPriceExclTax: checkItem.totalPriceExclTax,
    totalPriceInclTax: checkItem.totalPriceInclTax,
    unitQuantity: checkItem.unitQuantity,
    subItems: checkItem.subCheckItems?.map(createSalesGroupItemFromCheckItem),
    discountAmountExclTax: checkItem.discountAmountExclTax,
    discountAmountInclTax: checkItem.discountAmountInclTax,
    productPriceExclTax: checkItem.productPriceExclTax,
    productPriceInclTax: checkItem.productPriceInclTax,
    totalDiscountAmountExclTax: checkItem.totalDiscountAmountExclTax,
  }
}

export default createSalesGroupItemFromCheckItem
