import { CheckDetails } from '@ancon/wildcat-types'
import getFormattedCurrency from '@ancon/wildcat-utils/currency/getFormattedCurrency'

import { PayAndGoDiscount } from '../types'

export default function getPayAndGoCheckDiscount(
  check: CheckDetails,
): PayAndGoDiscount | null {
  const hasCheckDiscount = !!check.discountCode.discountId
  if (hasCheckDiscount) {
    const { discountCode, subTotalDiscountInclTax } = check

    let discountAmount = null

    if (subTotalDiscountInclTax.amount > 0) {
      discountAmount = getFormattedCurrency(
        subTotalDiscountInclTax.amount,
        subTotalDiscountInclTax.currency,
        { trimTrailingZeros: false },
      )
    }

    return {
      discountName: discountCode.discountName!,
      discountAmount,
    }
  }

  return null
}
